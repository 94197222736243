import defaultTaxes from "@/constants/defaultTaxes";

export default {
  type: "reseller-invoice-items",
  code: null,
  excerpt: '',
  unit_price: null,
  quantity: 1,
  discount: 0,
  relationshipNames: ["resellerInvoice", "resellerInvoiceable"],
  resellerInvoice: {
    type: "reseller-invoices",
    id: null,
  },
  resellerInvoiceable: {
    type: 'reseller-products',
    id: null,
  },
  pricing: {},
  taxes: defaultTaxes,
};
