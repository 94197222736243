<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.PAYMENTS") }}</span>
      </div>
    </div>

    <reseller-payment-list-table :filterInvoice="resellerInvoice.id" />

    <div class="row mb-5">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-6 text-right">
            {{
              resellerInvoice.total_paied !== undefined
                ? $formatCurrency(resellerInvoice.total_paied)
                : null
            }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import ResellerPaymentListTable from "../../ResellerPaymentManagement/partials/ResellerPaymentListTable.vue";

export default {
  name: "reseller-invoice-view-details",

  components: { ResellerPaymentListTable },

  mixins: [formMixin],

  props: ["resellerInvoice"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
